<template>
  <div class="register-right-section">
    <div class="custom-top" v-html="customTopHtml"></div>
    <template v-if="customHtml">
      <div class="list" v-html="customHtml"></div>
    </template>
    <template v-else-if="couponRegistration">
      <div class="list">
        <h3>{{ $t('registration.hl_redeeming_code') }}</h3>
        <ul>
          <li>{{ $t('registration.list_coupon_registration1') }}</li>
          <li>
            {{ $t('registration.list_coupon_registration2') }}
          </li>
          <li>{{ $t('registration.list_coupon_registration3') }}</li>
        </ul>
      </div>
    </template>
    <template v-else>
      <h3>{{ $t('registration.hl_protection_for_all_your_needs') }}</h3>
      <div class="features">
        <div class="feature">
          <picture>
            <img src="../assets/images/icons/icon-anti-virus.svg" alt />
          </picture>
          <h4>{{ $t('registration.hl_virus_protection') }}</h4>
          <p>{{ $t('registration.virus_protection_desc') }}</p>
        </div>
        <div class="feature">
          <picture>
            <img src="../assets/images/icons/icon-rtpn.svg" alt />
          </picture>
          <h4>{{ $t('registration.hl_browsing_protection') }}</h4>
          <p>{{ $t('registration.browsing_protection_desc') }}</p>
        </div>
        <div class="feature">
          <picture>
            <img src="../assets/images/icons/icon-scam-protection.svg" alt />
          </picture>
          <h4>{{ $t('registration.hl_scam_protection') }}</h4>
          <p>{{ $t('registration.scam_protection_desc') }}</p>
        </div>
        <div class="feature">
          <picture>
            <img
              src="../assets/images/icons/icon-ransomeware-protection.svg"
              alt
            />
          </picture>
          <h4>{{ $t('registration.hl_ransomware_protection') }}</h4>
          <p>{{ $t('registration.ransomware_protection_desc') }}</p>
        </div>
        <div class="feature">
          <picture>
            <img
              src="../assets/images/icons/icon-identity-protection.svg"
              alt
            />
          </picture>
          <h4>{{ $t('registration.hl_identity_protection') }}</h4>
          <p>{{ $t('registration.identity_protection_desc') }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    customHtml: {
      type: String
    },
    customTopHtml: {
      type: String
    },
    couponRegistration: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.register-right-section ::v-deep {
  padding: 53px 40px;

  p {
    color: $font-color;
  }

  .custom-top {
    p {
      margin-top: 0;
    }

    > p:last-child {
      margin-bottom: 48px;
    }
  }

  .list {
    h3 {
      text-align: left;
    }
  }

  h3 {
    text-align: center;
    padding: 0 0 20px;
    font-size: 1.87rem;
    font-weight: 500;
    color: $font-color;
    line-height: 1.21886em;
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      color: $font-color;
      font-size: 1rem;
      text-indent: -8px;
      padding-left: 20px;

      &:not(:last-child) {
        padding-bottom: 16px;
      }

      &::before {
        content: '\26ab';
        font-size: 0.3em;
        vertical-align: middle;
        display: inline-block;
        line-height: 0.4em;
        margin-top: -2px;
        color: #666;
        padding-right: 8px;
      }
    }
  }

  .features {
    display: flex;
    flex-wrap: wrap;
    max-width: 378px;
    margin: 0 auto;
    margin-top: 16px;

    .feature {
      width: 50%;
      text-align: center;
      padding: 16px;

      p {
        text-align: center;
        line-height: 16px;
        font-size: 12px;
        font-weight: 400;
        color: $font-color;
        margin: 0;
      }

      picture {
        display: inline-block;
        width: 59px;

        img {
          width: 100%;
        }
      }

      h4 {
        font-family: 'FSecureOffice';
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
        padding-top: 0px;
        color: #333333;
        line-height: 1.21886em;
        padding-bottom: 0.288em;
        margin: 0;
      }
    }
  }
}
</style>
